export const Endpoints = {
    GET_ALL_CV: "api/v2/cvs/",


    //multiformApi
    GET_SKILLS: "api/v2/skills/?limit=110",
    GET_INDUSTRY: "api/v2/industries/?limit=150",
    GET_COUNTRIES: "api/v2/countries/?limit=250",
    GET_JOB_TITLE: "api/v2/job-titles/",
    GET_LANGUAGE: "api/v2/languages/?limit=182",
    GET_DEGREES: "api/v2/degrees/",
}