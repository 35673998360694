exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LoginTemplate_loginContainer__2dOq3 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 30%;\n  height: 200px;\n  background-color: white;\n  border-top: 3px solid orange;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  margin-right: -50%;\n  margin-top: 20%;\n  transform: translate(-50%, -50%);\n  border-radius: 5px;\n  box-shadow: 0 5px 5px -3px rgba(58, 53, 65, 0.2), 0px 8px 10px 1px rgba(58, 53, 65, 0.14), 0px 3px 14px 2px rgba(58, 53, 65, 0.12); }\n  .LoginTemplate_loginContainer__2dOq3 .LoginTemplate_title__3KSvn {\n    font-size: 30px;\n    font-weight: 700;\n    color: gray;\n    text-align: center; }\n  .LoginTemplate_loginContainer__2dOq3 .LoginTemplate_loginButton__3m_gH {\n    background-color: #ffa500;\n    padding: 8px 15px;\n    font-size: 16px;\n    color: white;\n    border-radius: 5px;\n    border: none;\n    margin-top: 15px;\n    cursor: pointer; }\n", ""]);

// exports
exports.locals = {
	"loginContainer": "LoginTemplate_loginContainer__2dOq3",
	"title": "LoginTemplate_title__3KSvn",
	"loginButton": "LoginTemplate_loginButton__3m_gH"
};