exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ContactsStep_formContainer__foIjn {\n  padding-bottom: 24px; }\n  .ContactsStep_formContainer__foIjn .ContactsStep_input_experience__3YWPr {\n    width: 50%; }\n  .ContactsStep_formContainer__foIjn .ContactsStep_hr__NDe2a {\n    border: 1px solid #e0e0e0;\n    background-color: #e0e0e0; }\n  .ContactsStep_formContainer__foIjn .ContactsStep_wrapInputName__1Ebj7 {\n    display: flex; }\n  .ContactsStep_formContainer__foIjn .ContactsStep_firstName__3ouIx {\n    width: 100%;\n    padding: 16px 12px 16px 17px;\n    border: 1px solid rgba(58, 53, 65, 0.23);\n    border-radius: 6px;\n    color: black;\n    font-size: 16px;\n    margin: 0 4px 24px 0px; }\n  .ContactsStep_formContainer__foIjn .ContactsStep_lastName__3D670 {\n    width: 100%;\n    padding: 16px 12px 16px 17px;\n    border: 1px solid rgba(58, 53, 65, 0.23);\n    border-radius: 6px;\n    color: black;\n    font-size: 16px;\n    margin: 0 4px 24px 0px; }\n", ""]);

// exports
exports.locals = {
	"formContainer": "ContactsStep_formContainer__foIjn",
	"input_experience": "ContactsStep_input_experience__3YWPr",
	"hr": "ContactsStep_hr__NDe2a",
	"wrapInputName": "ContactsStep_wrapInputName__1Ebj7",
	"firstName": "ContactsStep_firstName__3ouIx",
	"lastName": "ContactsStep_lastName__3D670"
};