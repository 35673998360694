import { ADDITION_LOADING, GET_ADDITION_DONE, ADDITION_ERROR } from "../types/additionalTypes";


const initialState = {
    list:null,
    isLoading: false,
    error: null
};

const additionReduser = (state = initialState, action) => {
    switch (action.type) {
        case ADDITION_LOADING:
            return {
                ...state,
                isLoading: action.loading
            };


        case GET_ADDITION_DONE:
            return {
                ...state,
                list: action.payload // eslint-disable-line
            };
        case ADDITION_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
};

export default additionReduser;
