import { createAsyncThunk } from "@reduxjs/toolkit";

const thunkWrapper = (prefix, callback) => {
  const name = `${prefix}/${callback.name}`;

  return createAsyncThunk(name, async (params, thunkAPI) => {
    try {
      const response = await callback(params);

      const data = await response.data;
      let resp = {};

      if (response.status === 200 || response.status === 201) {
        resp = { ...data };
      } else {
        resp = thunkAPI.rejectWithValue(data);
      }

      return resp;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  });
};

export default thunkWrapper;
