import React, { useState, useEffect } from "react";

import styles from "./LoginTemplate.module.scss";
import axios from "axios";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { getLoginToken, getLoginURL } from "../AuthConfig";
import {
  setQueryParameters,
  getToken,
  LoginURL,
  code,
  session_state,
  state,
  client_info,
} from "../config/api";

const NavbarLogin = () => {
  useEffect(() => {
    if (LoginURL() === null) {
      getLoginURL();
    }
    if (window.location.href.indexOf("/authenticated") > -1 && getToken() === null) {
      const params = new URLSearchParams(window.location.search);
      const code = params.get("code");
      const session_state = params.get("session_state");
      const state = params.get("state");
      const client_info = params.get("client_info");
      setQueryParameters(code, session_state, state, client_info);
    }
  }, [window.location]);

  const login = () => {
    getLoginToken(code(), session_state(), state(), client_info());
  };

  return (
    <div className={styles.loginContainer}>
      <div>
        <h1 className={styles.title}>JetCV</h1>
        <div>
          <button onClick={login} className={styles.loginButton}>
            Login with Office365
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavbarLogin;
