import React, { useState, forwardRef } from "react";
// import "../../../App.css";
import { SvgIconsColor } from "../../Download/PicturesDownload/download";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Delete from "@mui/icons-material/Delete";
import MaterialTable, { MTableToolbar, TablePagination } from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../../../themeConfig/ThemeConfig";

const tableIcons = {
  Edit: forwardRef((props, ref) => (
    <ModeEditOutlinedIcon style={{ color: "#8A8D93" }} {...props} ref={ref} />
  )),
  Delete: forwardRef((props, ref) => <Delete style={{ color: "#8A8D93" }} {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => null),
  LastPage: forwardRef((props, ref) => null),
  Download: forwardRef((props, ref) => <SvgIconsColor {...props} ref={ref} />),
  Search: forwardRef((props, ref) => (
    <SearchIcon style={{ color: "#8A8D93" }} {...props} ref={ref} />
  )),
};

const useStyles = makeStyles({
  myComponent: {
    // "& .MTableToolbar-actions-9 > div > div > span > .MuiButtonBase-root": {
    //     display: "none !important",
    // },
    // "& div.MuiInput-underline:before": {
    //     display: "none !important",
    // },
    "& tbody.MuiTableBody-root >.MuiTableRow-root:hover": {
      backgroundColor: "rgba(235, 241, 254, 0.49) !important",
    },
    "& .MuiTableCell-root": {
      width: "calc(279px) !important",
    },
    "& .MuiPaper-elevation2": {
      boxShadow: "none",
    },
    "& tbody.MuiTableBody-root > tr:nth-last-child(-n+2):not(:last-child)": {
      height: "55.88px !important",
    },
  },
});

const Table = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const classes = useStyles();
  const empList = [
    {
      id: 1,
      name: "AIvan Petruk",
      position: "Python/.NET developer",
      experience: "2 years",
      created: "14.07.2020",
    },
    {
      id: 2,
      name: "Ivan Petruk",
      position: "VPython/.NET developer",
      experience: "5 years",
      created: "15.07.2020",
    },
    {
      id: 3,
      name: "Ivan Petruk",
      position: "Python/.NET developer",
      experience: "7 years",
      created: "14.07.2020",
    },
    {
      id: 4,
      name: "BIvan Petruk",
      position: "APython/.NET developer",
      experience: "5 years",
      created: "14.07.2020",
    },
    {
      id: 5,
      name: "Ivan Petruk",
      position: "Python/.NET developer",
      experience: "9 years",
      created: "19.07.2020",
    },
    {
      id: 6,
      name: "Ivan Petruk",
      position: "Python/.NET developer",
      experience: "5 years",
      created: "16.07.2020",
    },
    {
      id: 6,
      name: "Ivan Petruk",
      position: "Python/.NET developer",
      experience: "5 years",
      created: "14.07.2020",
    },
    {
      id: 6,
      name: "Ivan Petruk",
      position: "Python/.NET developer",
      experience: "5 years",
      created: "14.07.2020",
    },
    {
      id: 6,
      name: "Ivan Petruk",
      position: "Python/.NET developer",
      experience: "5 years",
      created: "14.07.2020",
    },
    {
      id: 6,
      name: "Ivan Petruk",
      position: "Python/.NET developer",
      experience: "5 years",
      created: "14.07.2020",
    },
    {
      id: 6,
      name: "Ivan Petruk",
      position: "Python/.NET developer",
      experience: "5 years",
      created: "14.07.2020",
    },
    {
      id: 6,
      name: "Ivan Petruk",
      position: "Python/.NET developer",
      experience: "5 years",
      created: "14.07.2020",
    },
    {
      id: 6,
      name: "Ivan Petruk",
      position: "Python/.NET developer",
      experience: "5 years",
      created: "14.07.2020",
    },
    {
      id: 6,
      name: "Ivan Petruk",
      position: "Python/.NET developer",
      experience: "5 years",
      created: "14.07.2020",
    },
  ];
  const [data, setData] = useState(empList);
  const columns = [
    { title: "FULL NAME", field: "name" },
    { title: "POSITION", field: "position" },
    { title: "EXPERIENCE", field: "experience" },
    { title: "CREATED", field: "created" },
  ];

  return (
    <div
      style={{
        position: "relative",
        width: "1116px",
        margin: "0 auto",
        borderRadius: "6px",
        borderTop: "1px solid #F9FAFC",
        borderRight: "1px solid #F9FAFC",
        borderLeft: "1px solid #F9FAFC",
      }}
      className={classes.myComponent}>
      <ThemeProvider theme={theme}>
        <MaterialTable
          style={{ width: "1116px", margin: "0 auto", borderRadius: "6px" }}
          title=""
          icons={tableIcons}
          data={data}
          columns={columns}
          editable={{
            onRowAdd: (newRow) =>
              new Promise((resolve) => {
                const updatedRows = [...data, { id: Math.floor(Math.random() * 100), ...newRow }];
                setTimeout(() => {
                  setData(updatedRows);
                  resolve();
                }, 2000);
              }),
            onRowDelete: (selectedRow) =>
              new Promise((resolve) => {
                const index = selectedRow.tableData.id;
                const updatedRows = [...data];
                updatedRows.splice(index, 1);
                setTimeout(() => {
                  setData(updatedRows);
                  resolve();
                }, 2000);
              }),
            onRowUpdate: (updatedRow, oldRow) =>
              new Promise((resolve) => {
                const index = oldRow.tableData.id;
                const updatedRows = [...data];
                updatedRows[index] = updatedRow;
                setTimeout(() => {
                  setData(updatedRows);
                  resolve();
                }, 2000);
              }),
            onBulkUpdate: (selectedRows) =>
              new Promise((resolve) => {
                const rows = Object.values(selectedRows);
                const updatedRows = [...data];
                let index;
                rows.map((emp) => {
                  index = emp.oldData.tableData.id;
                  updatedRows[index] = emp.newData;
                });
                setTimeout(() => {
                  setData(updatedRows);
                  resolve();
                }, 2000);
              }),
          }}
          onRowClick={(evt, selectedRow) => setSelectedRow(selectedRow.tableData.id)}
          options={{
            root: {
              "& div.MuiTableRow-root": {
                display: "none",
              },
            },
            searchFieldStyle: {
              top: "-30px",
              fontSize: "16px",
              border: "1px solid #C2C6D3",
              width: "226px",
              height: "42px",
              color: "rgba(58, 53, 65, 0.68)",
              borderRadius: "5px",
              background: "#EBF1FE",
              padding: "0 16px",
              position: "absolut",
              // top: '-90px',
              zIndex: "-1",
            },
            headerStyle: {
              backgroundColor: "#F9FAFC",
            },
            actionsColumnIndex: -1,
            addRowPosition: "first",
            rowStyle: (rowData) => ({
              backgroundColor:
                selectedRow === rowData.tableData.id ? "rgba(235, 241, 254, 0.49)" : "#FFF",
              boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
            }),
          }}
          actions={[
            {
              icon: tableIcons.Download,
              tooltip: "Save User pdf",
              onClick: (event, rowData) => alert("You saved " + rowData.name),
            },
          ]}
          components={{
            //         Pagination: props => (
            //             <div>
            //             <TablePagination {...props}
            //              labelRowsPerPage={<div style={{fontSize: 14}}>{props.labelRowsPerPage}</div>}
            //              labelDisplayedRows={row => <div style={{fontSize: 14}}>{props.labelDisplayedRows(row)}</div>}
            //              SelectProps={{
            //                style:{
            //                  fontSize: 14
            //                }
            //              }}
            //             rowsPerPageOptions={[5, 10, 20, 30]}
            //             />
            //             </div>
            //    ),
            Toolbar: (props) => (
              <div style={{ padding: 0 }}>
                <MuiToolbar-root {...props} />
              </div>
            ),
          }}
        />
      </ThemeProvider>
    </div>
  );
};
export default Table;
