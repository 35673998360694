import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none !important",
  },
  "& .MuiInput-underline:before": {
    display: "none !important",
  },
  palette: {},
  props: {
    MuiTooltip: {
      arrow: true,
    },
  },
  typography: {
    fontFamily: "Inter",
    fontWeigthLigth: 400,
    fontWeigthRegular: 500,
    fontWeigthMedium: 600,
    fontWeigthBold: 700,
  },
});

export default theme;
