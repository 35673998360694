import axios from "axios";
import { Endpoints } from "./endpoints";
const { REACT_APP_API_URL } = process.env;

export const instance = axios.create({
    baseURL: REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    }
})

export const CV_API = {
    getAllCv(pageSize,offsetPage) {
        return instance.get(Endpoints.GET_ALL_CV,{
            params:{
                limit:pageSize,
                offset: offsetPage
            }
        });
    },
}

export const additional_API = {
    async getAllAddition() {
        return (
            await Promise.all([
                instance.get(Endpoints.GET_SKILLS),
                instance.get(Endpoints.GET_INDUSTRY),
                instance.get(Endpoints.GET_COUNTRIES),
                instance.get(Endpoints.GET_JOB_TITLE),
                instance.get(Endpoints.GET_LANGUAGE),
                instance.get(Endpoints.GET_DEGREES),
            ])
        )
    }
}