import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, IconButton } from "@mui/material";
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import useStyles from "../../override";
import { v4 as uuid } from "uuid";
import { AuthHeader } from "../../constants/authHeader";
const { REACT_APP_API_URL } = process.env;

const monthData = [
  "Jan",
  "Feb",
  "March",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const yearData = () => {
  let current = new Date().getFullYear();
  let startYear = 1980
  let allYears = []
  while (startYear <= current) {
    allYears.push((startYear++).toString())
  }
  return allYears;
}

const CertificationStep = ({
  updateData,
  handleCertification,
  handlePreviousCertification,
  previousCertification,
}) => {
  const [formFields, setFormFields] = useState([
    {
      certificate_name: "",
      description: "",
      date_of_obtaining_month: "",
      date_of_obtaining_year: "",
    },
  ]);
  const [pageNum] = useState({ name: 4 });
  const [formValid, setFormValid] = useState(false);
  const [scrollBtnUp, setScrollBtnUp] = useState(false)
  const [scrollBtnDown, setScrollBtnDown] = useState(false)

  useEffect(() => {
    if (previousCertification && previousCertification[0]) {
      setFormFields(previousCertification);
    }
  }, [previousCertification]);

  const submit = (e) => {
    e.preventDefault();
    handleCertification(formFields);
    handlePreviousCertification(formFields);
    updateData(pageNum.name);
  };

  const addFields = () => {
    let object = {
      certification_name: "",
      date_of_obtaining_month: "",
      description: "",
      date_of_obtaining_year: "",
    };
    setFormFields([...formFields, object]);
  };
  const removeFields = (event, index) => {
    event.preventDefault()
    // setFormFields([...formFields].splice(index, 1));
    setFormFields(formFields.filter((item, ind) => ind !== index));
  };
  const handleFormChange = (event, index, value, id) => {
    let data = [...formFields];
    if (value !== undefined) {
      data[index][id] = value;
    } else {
      data[index][event.target.name] = event.target.value;
    }
    setFormFields(data);
  };

  const onToggleBack = () => {
    updateData(pageNum.name - 2);
  };

  const classes = useStyles();
  useEffect(() => {
    if (formFields.length > 1) {
      scrollFunction()
    } else {
      setScrollBtnDown(false)
    }
  }, [formFields.length])

  window.onscroll = function () { scrollFunction() };
  function scrollFunction() {
    if ((window.innerHeight + window.pageYOffset) >= (document.body.offsetHeight - 20)) {
      setScrollBtnDown(false)
    }
    if ((window.innerHeight + window.pageYOffset + 20) < document.body.offsetHeight) {
      setScrollBtnDown(true)
    }
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      setScrollBtnUp(true)
    } else {
      setScrollBtnUp(false)
    }
  }

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  function downFunction() {
    document.body.scrollTop = document.body.offsetHeight;
    document.documentElement.scrollTop = document.body.offsetHeight;
  }

  return (
    <div>
      <div>
        {scrollBtnUp &&
          <IconButton
            onClick={topFunction}
            size="large"
            sx={{ height: "80px", width: "80px", position: "fixed", right: "50px", top: "50px" }}
          >
            <KeyboardArrowUpOutlinedIcon fontSize="inherit" sx={{ height: "60px", width: "45px", color: "#9155fd" }} />
          </IconButton>
        }
        {scrollBtnDown &&
          <IconButton
            onClick={downFunction}
            size="large"
            sx={{ height: "80px", width: "80px", position: "fixed", right: "50px", bottom: "50px" }}
          >
            <KeyboardArrowDownOutlinedIcon fontSize="inherit" sx={{ height: "60px", width: "45px", color: "#9155fd" }} />
          </IconButton>
        }
        <div>
          <div>
            <div className="formContainer">
              <form onSubmit={submit}>
                {formFields.map((form, index) => {
                  
                  return (
                    <>
                      <h2 className="title">Certification {index + 1}</h2>

                      <div className="label" key={index}>
                        For example Professional Scrum Master
                      </div>
                      <input
                        name="certificate_name"
                        type="text"
                        className="form_input"
                        value={form.certificate_name}
                        onChange={(e) => handleFormChange(e, index)}
                        placeholder="Certificate Name"
                      />
                      <input
                        name="description"
                        type="text"
                        className="form_input"
                        value={form.description}
                        onChange={(e) => handleFormChange(e, index)}
                        placeholder="Description"
                      />

                      <div className="dateBlock">
                        <div className="dateLabel">Date of obtaining</div>
                        <div className="dateSelect">
                          <div className="dateInput">
                            <Autocomplete
                              name="date_of_obtaining_month"
                              className={classes.selectPicker}
                              value={form.date_of_obtaining_month}
                              getOptionLabel={(option) =>
                                typeof option === "string" || option instanceof String ? option : ""
                              }
                              onChange={(e, value) =>
                                handleFormChange(e, index, value, "date_of_obtaining_month")
                              }
                              disablePortal
                              id="combo-box-demo"
                              options={monthData}
                              renderInput={(params) => <TextField {...params} label="Month" />}
                            />
                          </div>
                          <div className="dateInput">
                            <Autocomplete
                              name="date_of_obtaining_year"
                              className={classes.selectPicker}
                              value={form.date_of_obtaining_year}
                              getOptionLabel={(option) =>
                                typeof option === "string" || option instanceof String ? option : ""
                              }
                              onChange={(e, value) =>
                                handleFormChange(e, index, value, "date_of_obtaining_year")
                              }
                              disablePortal
                              id="combo-box-demo"
                              options={yearData()}
                              renderInput={(params) => <TextField {...params} label="Years" />}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="cancelContainerExperience">
                        {formFields.length > 1 && (
                          <button className="buttonCancel" onClick={(event) => removeFields(event, index)}>
                            Remove
                          </button>
                        )}
                      </div>
                    </>
                  );
                })}
              </form>
              <div className="buttonsContainerExperienc">
                <button className="buttonSave" onClick={addFields}>
                  Add New Certificate
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="buttonsContainer">
          <button className="buttonCancel" onClick={onToggleBack}>
            Back
          </button>
          <button
            className={formValid ? "buttonSaveDisabled" : "buttonSave"}
            onClick={submit}
            disabled={formValid}>
            SAVE & NEXT
          </button>
        </div>
      </div>
    </div>
  );
};

export default CertificationStep;
