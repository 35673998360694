// import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from "redux-thunk";
import allCvReduser from "./reducers/allCvReduser";
import additionReduser from "./reducers/additionReducer";
import { usersSlice, loginSlice } from "../pages/UsersPage/UsersSlice";

// export const store = configureStore({
//   reducer: {
//     // users: combineReducers({
//     //   getAll: loginSlice.reducer,
//     // }),
//     users: combineReducers({
//       getAll: usersSlice.reducer,
//     }),
//   },
// });
const rootReducer = combineReducers({
  allCvReduser,
  additionReduser
});
const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
export default store;