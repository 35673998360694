import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Document, Page } from "react-pdf";
import preview from "../../../assets/pictures/preview.png";
import download from "../../../assets/pictures/download.png";
import styles from "./EmployeeСv.module.scss";
import { AuthHeader } from "../../../constants/authHeader";
//import samplePDF from "../../pdfsample.pdf";
import { onRefreshToken } from "../../../AuthConfig";
import { instance } from "../../../Api/apis";
import { Endpoints } from "../../../Api/endpoints";
const { REACT_APP_API_URL, } = process.env;

const EmployeeCv = () => {
  const [pdfCv, setCvPdf] = useState("");
  const [numPages, setNumberPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const history = useHistory();

  const userCv = history.location.state.userCv;
  function onDocumentLoadSuccess({ numPages }) {
    setNumberPages(numPages);
    setPageNumber(1);
  }
  function changePage(offSet) {
    setPageNumber((prevPageNumber) => prevPageNumber + offSet);
  }

  function changePageBack() {
    changePage(-1);
  }

  function changePageNext() {
    changePage(+1);
  }

  const back = () => {
    history.push({
      pathname: "/allcvs",
    });
  };

  const url = `${REACT_APP_API_URL}api/v2/cvs/${userCv.id}/pdf`;

  let downloadFile = () => {
    const aElement = document.createElement("a");
    aElement.setAttribute("download", `${userCv.name}.pdf`);
    aElement.href = pdfCv;
    aElement.setAttribute("target", "_blank");
    aElement.click();
    URL.revokeObjectURL(pdfCv);
  };

  const getEmployeeCv = () => {
    fetch(url, {
      method: "GET",
      headers: new Headers(AuthHeader),
    })
      .then((res) => {
        if (res.status === 403) {
          onRefreshToken();
        } else {
          return res.blob();
        }
      })
      .then((res) => {
        const href = URL.createObjectURL(res);
        setCvPdf(href);
      })
      .then((data) => {
        console.log(data);
      });
  };
  const getCvById = async (id) => {
    try {
      const res = await instance.get(`${Endpoints.GET_ALL_CV}${userCv.id}`)
      const edite = res.data
      history.push({
        pathname: "/steps",
        state: { edite },
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getEmployeeCv();
  }, []);
  return (
    <div className={styles.containerCv}>
      <div className={styles.buttonGrup}>
        <button className={styles.previewBtn} type="text" onClick={getCvById}>
          EDIT
        </button>
        <button className={styles.previewBtn} style={{ marginLeft: "15px" }} type="text" onClick={back}>
          BACK
        </button>
        <button className={styles.downloadBtn} type="text" onClick={downloadFile}>
          <img
            className={styles.imgDownload}
            alt="logo"
            width="11.67px"
            height="14.17px"
            src={download}
          />
          DOWNLOAD
        </button>
      </div>
      <div className={styles.containerPdf}>
        <Document file={pdfCv} onLoadSuccess={onDocumentLoadSuccess}>
          <Page height="700" pageNumber={pageNumber} />
        </Document>
        <div className={styles.pagination}>
          <p className={styles.title}>
            {" "}
            Page {pageNumber} of {numPages}
          </p>
          {pageNumber > 1 && (
            <button className={styles.btnPdf} onClick={changePageBack}>
              Previous Page
            </button>
          )}
          {pageNumber < numPages && (
            <button className={styles.btnPdf} onClick={changePageNext}>
              Next Page
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployeeCv;
