import axios from "axios";
import {
  clearToken,
  setTokens,
  getToken,
  getRefreshToken,
  setUser,
  setLoginURL,
} from "../config/api";

import endpoints from "../config/constants/endpoints";
import { ROUTES } from "../constants/routes";

const { REACT_APP_API_URL } = process.env;

export const getLoginURL = () => {
  axios
    .get(`${REACT_APP_API_URL}microsoft-auth/get-login-url`, {
      params: { redirect_url: `${window.location.origin}/authenticated` },
    })
    .then((data) => {
      if (data.status === 200) {
        const response = data.data;
        const uri = response.auth_uri;

        setLoginURL(response);
        window.location.href = uri;
      }
    })
    .catch((err) => console.error(err));
};

export const getLoginToken = (code, session_state, state, client_info) => {
  const loginURL = JSON.parse(localStorage.getItem("loginURL"));
  const headers = {
    "Content-Type": "application/json",
  };
  if (loginURL) {
    const data = {
      auth_msal_params: {
        auth_uri: loginURL.auth_uri,
        claims_challenge: loginURL.claims_challenge,
        code_verifier: loginURL.code_verifier,
        nonce: loginURL.nonce,
        redirect_uri: loginURL.redirect_uri,
        scope: loginURL.scope,
        state: loginURL.state,
      },
      auth_server_params: {
        code: code,
        session_state: session_state,
        state: state,
        client_info: client_info,
      },
    };
    axios
      .post(`${REACT_APP_API_URL}microsoft-auth/redirect-url`, data, {
        headers: headers,
      })
      .then((response) => {
        const token = response.data.access_token;
        setTokens(response.data.access_token, response.data.refresh_token);
        parseJwt(token);
        window.location.href = ROUTES.home;
      })
      .catch((error) => {
        console.log(error);
        clearRidirect();
      });
  }
};

export const onLogout = () => {
  deleteRefreshToken();
  deleteToken();
  clearRidirect();
};

export const onRefreshToken = () => {
  onLogout();
};
export const deleteToken = () => {
  const revokeHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getToken()}`,
  };
  axios
    .delete(`${REACT_APP_API_URL}microsoft-auth/revoke-access`, {
      headers: revokeHeaders,
    })
    .then((response) => {
      console.log("deleteToken data: ", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const deleteRefreshToken = () => {
  const refreshHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getRefreshToken()}`,
  };

  axios
    .delete(`${REACT_APP_API_URL}microsoft-auth/revoke-refresh`, {
      headers: refreshHeaders,
    })
    .then((response) => {
      console.log("deleteRefreshToken data: ", response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

const clearRidirect = () => {
  localStorage.clear();
  window.location.href = ROUTES.home;
};

const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  setUser(jsonPayload);
};
