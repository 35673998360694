import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Tabs from "../Tab/Tab";
import Table from "../../Components/shared/Table/Table";

import styles from "./TableTemplate.module.scss";

import { Dropdown } from "../shared/Dropdown/Dropdown";
import SearchInput from "../SearchInput/SearchInput";
import burgnotactive from "../../assets/pictures/burgnotactive.png";
import burgactive from "../../assets/pictures/burgactive.png";

const TableTemplate = () => {
  const [shouldRedirect, onShouldRedirect] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (shouldRedirect) {
      history.push("/mycvs");
    }
  });

  const onTogleBack = () => {
    onShouldRedirect(true);
  };
  return (
    <div className={styles.allCvs}>
      <div className={styles.tabsContainer}>
        <Tabs />
      </div>
      <div className={styles.mains}>
        <div className={styles.filtersContainer}>
          <SearchInput />
          <div className={styles.dropdowns}>
            <Dropdown width={226} />
          </div>
          <div className={styles.dropdowns}>
            <Dropdown width={226} />
          </div>
        </div>
        <div className={styles.burgersContainer}>
          <img
            className={styles.imgBurg}
            alt="burgactive"
            width="26.25px"
            height="23.33px"
            src={burgactive}
          />
          <img
            className={styles.imgBurgvertical}
            onClick={onTogleBack}
            alt="burgnotactive"
            width="27.71px"
            height="26.25px"
            src={burgnotactive}
          />
        </div>
      </div>
      <div>
        <Table />
      </div>
    </div>
  );
};
export default TableTemplate;
