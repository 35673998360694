import React, { useMemo } from "react";
import { Redirect, Route } from "react-router-dom";
import { ROUTES } from "../constants/routes";

export const DataContext = React.createContext({});

export const PrivateRoute = ({ component: Component, authenticated, ...rest }) => {
  // temporarily
  const users = [{ id: 1, name: "one" }];

  const value = useMemo(() => users, [users]);

  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? (
          <DataContext.Provider value={value}>
            <Component {...props} />
          </DataContext.Provider>
        ) : (
          <Redirect to={ROUTES.login} />
        )
      }
    />
  );
};
