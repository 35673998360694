import React from "react";
import PropTypes from "prop-types";
import NavbarLogin from "../NavbarLogin";
import NavbarLogout from "../NavbarLogout";
import logo from "../../assets/pictures/logo.png";

const Header = ({ authenticated }) => {
  return (
    <div className="header">
      <div className="Logo">
        <img alt="logo" width="77px" height="21px" src={logo} />
      </div>
      {authenticated ? <NavbarLogout /> : <NavbarLogin />}
    </div>
  );
};

Header.propTypes = {
  authenticated: PropTypes.bool,
};

export { Header };
