import React from "react";
import styles from './SearchInput.module.scss';
import searchLogo from '../../assets/pictures/search.png';

 const SearchInput = () => {
  return <div className={styles.searchInput}>
    <button className={styles.searchInput__searchButton}>
      <img src={searchLogo} alt='search-icon' className={styles.searchInput__icon}/>
    </button>
    <input className={styles.searchInput__input} placeholder='Find by Name' />
  </div>
}
export default SearchInput;
