exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PreviewStep_info__3c0uf {\n  font-size: 16px;\n  color: #3A3541DE;\n  line-height: 24px; }\n\n.PreviewStep_buttonsContainer__cZj-H {\n  display: flex;\n  justify-content: end;\n  grid-gap: 8px;\n  gap: 8px;\n  margin-top: 16px;\n  padding-bottom: 19px; }\n  .PreviewStep_buttonsContainer__cZj-H .PreviewStep_button__1bRH- {\n    padding: 5px 13px;\n    border: 1px solid #3A3541AD;\n    border-radius: 5px;\n    background-color: inherit;\n    font-size: 14px;\n    color: #3A3541DE;\n    font-weight: 500;\n    cursor: pointer; }\n    .PreviewStep_buttonsContainer__cZj-H .PreviewStep_button__1bRH- .PreviewStep_editContainer__2kPMn, .PreviewStep_buttonsContainer__cZj-H .PreviewStep_button__1bRH- .PreviewStep_deleteContainer__Tdknq {\n      display: flex;\n      grid-gap: 7px;\n      gap: 7px; }\n", ""]);

// exports
exports.locals = {
	"info": "PreviewStep_info__3c0uf",
	"buttonsContainer": "PreviewStep_buttonsContainer__cZj-H",
	"button": "PreviewStep_button__1bRH-",
	"editContainer": "PreviewStep_editContainer__2kPMn",
	"deleteContainer": "PreviewStep_deleteContainer__Tdknq"
};