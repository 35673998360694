exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SkillsStep_explanation__c8m-8 {\n  color: rgba(0, 0, 0, 0.38);\n  margin-bottom: 15px; }\n\n.SkillsStep_divider__2hM-b {\n  text-align: center;\n  color: rgba(0, 0, 0, 0.38);\n  display: flex;\n  align-items: center;\n  margin-bottom: 25px; }\n  .SkillsStep_divider__2hM-b::before {\n    content: \" \";\n    flex: 1 1;\n    border-bottom: 1px solid rgba(0, 0, 0, 0.38);\n    margin-right: 15px; }\n  .SkillsStep_divider__2hM-b::after {\n    content: \" \";\n    flex: 1 1;\n    border-bottom: 1px solid rgba(0, 0, 0, 0.38);\n    margin-left: 15px; }\n", ""]);

// exports
exports.locals = {
	"explanation": "SkillsStep_explanation__c8m-8",
	"divider": "SkillsStep_divider__2hM-b"
};