import React, { useEffect, useState } from "react";
import styles from './SkillsStep.module.scss';
import {
  Box,
  Chip,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select
} from "@mui/material";
import useStyles from "../../override";

const {REACT_APP_API_URL} = process.env;


export const baseURL = `${REACT_APP_API_URL}/`;
const SkillsStep = ({
                      updateData,
                      handleSkills,
                      handlePreviousSkills,
                      previousSkills,
                      prLanguagesData,
                      prLanguagesConfig
                    }) => {
  const [ generalInfo, setGeneralInfo ] = useState();
  const [ prLanguages, setPrLanguages ] = useState([]);
  const [ frameworks, setFrameworks ] = useState("");
  const [ bugs, setBugs ] = useState(previousSkills.bugs ? previousSkills.bugs : "");
  const [ cvs, setCvs ] = useState(previousSkills.cvs ? previousSkills.cvs : "");
  const [ patterns, setPatterns ] = useState(previousSkills.patterns ? previousSkills.patterns : "");
  const [ others, setOthers ] = useState(previousSkills.other_skills ? previousSkills.other_skills : "");
  const [ pageNum, setPageNum ] = useState({name: 6});
  const [ formValid, setFormValid ] = useState(false);
  const [ open, setOpen ] = useState(false);


  useEffect(() => {
    if (previousSkills.general_info) {
      setGeneralInfo(previousSkills.general_info);
    }
    if (previousSkills.skills) {
      setPrLanguages(previousSkills.skills);
    }
    if (previousSkills.frameworks) {
      setFrameworks(previousSkills.frameworks);
    }
    if (previousSkills.bugs) {
      setBugs(previousSkills.bugs);
    }
    if (previousSkills.devops_skills) {
      setCvs(previousSkills.devops_skills);
    }

    if (previousSkills.other_skills) {
      setOthers(previousSkills.other_skills);
    }
    if (previousSkills.patterns) {
      setPatterns(previousSkills.patterns);
    }
  }, [ previousSkills ]);

  // useEffect(() => {
  //   if (prLanguages.length && prLanguages.length >= 1) {
  //     setFormValid(false);
  //   } else {
  //     setFormValid(true);
  //   }
  // }, [prLanguages]);
  const onSubmit = () => {
    handleSkills({
      general_info: generalInfo,
      skills: prLanguages,
      // program_languages: prLanguages.map(l => prLanguagesConfig.find(lang => lang.title === l).id),
      extra_skills: frameworks,
      bug_tracking_and_pm: bugs,
      devops_skills: cvs,
      design_patterns: patterns,
      other_skills: others,
    });
    handlePreviousSkills({
      general_info: generalInfo,
      skills: prLanguages,
      frameworks: frameworks,
      bugs: bugs,
      cvs: cvs,
      patterns: patterns,
      other_skills: others,
    });
    updateData(pageNum.name);
  };

  const onToggleBack = () => {
    updateData(pageNum.name - 2);
  };

  const handleDelete = (chipToDelete) => () => {
    setPrLanguages(prLanguages.filter((item) => item !== chipToDelete));
  };

  const handleChange = (event) => {
    const {
      target: {value},
    } = event;
    setPrLanguages(typeof value === "string" ? value.split(",") : value);
  };

  const classes = useStyles();


  return (
    <div>
      <div>
        <div>
          <div className="formContainer">
            <h2 className="title">Skills</h2>
            <p className={styles.explanation}>Fill General Info or fields below</p>
            <form>
              <textarea
                type="text"
                className="form_input"
                style={{maxWidth: "100%", minWidth: "100%", height: "52px", minHeight: "52px"}}
                value={generalInfo}
                onChange={(e) => setGeneralInfo(e.target.value)}
                placeholder="General info"
                disabled={frameworks || prLanguages.length || bugs || cvs || patterns || others}
              />
              <div className={styles.divider}>or</div>
              <FormControl sx={{width: "100%"}}>
                {/* <InputLabel id="demo-multiple-chip-label">
                  Programming Languages/ Technologies
                </InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  className={classes.selectPicker}
                  multiple
                  value={prLanguages}
                  onClick={() => setOpen(!open)}
                  open={open}
                  onChange={handleChange}
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="Programming Languages/ Technologies"
                    />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} onDelete={handleDelete(value)} />
                      ))}
                    </Box>
                  )}>
                  {prLanguagesData.map((language) => (
                    <MenuItem key={language} value={language}>
                      {language}
                    </MenuItem>
                  ))}
                </Select> */}
                <Autocomplete
                  multiple
                  id="tags-filled"
                  className={classes.selectPicker}
                  value={prLanguages}
                  options={prLanguagesData.map((option) => option)}
                  freeSolo
                  onChange={(e, value) => setPrLanguages(value)}
                  disabled={generalInfo}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip key={value} label={option} {...getTagProps({index})} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Programming Languages/ Technologies"
                      placeholder="Add your skill"
                    />
                  )}
                />
              </FormControl>
              <textarea
                type="text"
                className="form_input"
                style={{maxWidth: "100%", minWidth: "100%", height: "52px", minHeight: "52px"}}
                value={frameworks}
                onChange={(e) => setFrameworks(e.target.value)}
                placeholder="Frameworks/Libraries"
                disabled={generalInfo}
              />
              <textarea
                type="text"
                className="form_input"
                style={{maxWidth: "100%", minWidth: "100%", height: "52px", minHeight: "52px"}}
                value={bugs}
                onChange={(e) => setBugs(e.target.value)}
                placeholder="Bug Tracking/Project Management"
                disabled={generalInfo}
              />
              <textarea
                type="text"
                className="form_input"
                style={{maxWidth: "100%", minWidth: "100%", height: "52px", minHeight: "52px"}}
                value={cvs}
                onChange={(e) => setCvs(e.target.value)}
                placeholder="Software/CVS/Build Automation"
                disabled={generalInfo}
              />
              <textarea
                type="text"
                className="form_input"
                style={{maxWidth: "100%", minWidth: "100%", height: "52px", minHeight: "52px"}}
                value={patterns}
                onChange={(e) => setPatterns(e.target.value)}
                placeholder="Design Patterns"
                disabled={generalInfo}
              />
              <textarea
                type="text"
                className="form_input"
                style={{maxWidth: "100%", minWidth: "100%", height: "52px", minHeight: "52px"}}
                value={others}
                onChange={(e) => setOthers(e.target.value)}
                placeholder="Others"
                disabled={generalInfo}
              />
            </form>
          </div>
        </div>
        <div className="buttonsContainer">
          <button className="buttonCancel" onClick={onToggleBack}>
            Back
          </button>
          <button
            className={formValid ? "buttonSaveDisabled" : "buttonSave"}
            onClick={onSubmit}
            disabled={formValid}>
            SAVE & NEXT
          </button>
        </div>
      </div>
    </div>
  );
};

export default SkillsStep;
