import { createSlice } from "@reduxjs/toolkit";


const defaultReducer = (thunk, arr = false) =>
  createSlice({
    name: thunk.typePrefix,
    initialState: {
      isFetching: false,
      isSuccess: false,
      isError: false,
      errorMessage: "",
      data: arr ? [] : null,
    },
    reducers: {
      clearState: (state) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.isError = false;
      },
      updateStateData: (state, { payload }) => {
        state.data = payload;
      },
    },
    extraReducers: {
      [thunk.fulfilled.type]: (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data = payload;
      },
      [thunk.pending.type]: (state) => {
        state.isFetching = true;
      },
      [thunk.rejected.type]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.errorMessage;
      },
    },
  });

export default defaultReducer;
