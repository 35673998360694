import React from "react";

export const Footer = () => {
  return (
    <div className="footer">
      <div style={{ color: "rgba(58, 53, 65, 0.68)" }}>
        © 2023, Made for <span style={{ color: "#9155FD" }}>JetHRMS</span>
      </div>
      <div style={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "#9155FD" }}>
        <span style={{ marginRight: "16px" }}>Documentation</span>
        <span>Support</span>
      </div>
    </div>
  );
};
