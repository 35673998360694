import React, { useState, Suspense } from "react";
//import SinglePagePDFViewer from "../pdf/single-page";
import preview from "../../assets/pictures/preview.png";
import download from "../../assets/pictures/download.png";
import styles from "./Download.module.scss";
import LeftStepper from "../LeftStepper/LeftStepper";
import EditeIcon from "../../assets/pictures/Edit.png";
//import samplePDF from "../../pdfsample.pdf";

const Download = ({ pdfCv, handleDownload, updateData,editId,editCV }) => {
  const [viewPdf, setViewPdf] = useState(true);

  const onToggle = () => setViewPdf((preViewPdf) => !preViewPdf);
  return (
    <div>
      <div>
        <div>
          <Suspense fallback={<div>Loading...</div>}>
            <div className={(styles.download, styles.margin)}>
              <div className={styles.buttonGrup}>
                <button onClick={editCV} className={styles.previewBtn} type="text">
                  <img
                    className={styles.imgPreview}
                    alt="logo"
                    width="18.33px"
                    height="12.5px"
                    src={EditeIcon}
                  />
                  EDIT
                </button>
                <button onClick={onToggle} className={styles.previewBtn} type="text">
                  <img
                    className={styles.imgPreview}
                    alt="logo"
                    width="18.33px"
                    height="12.5px"
                    src={preview}
                  />
                  PREVIEW
                </button>
                <button className={styles.downloadBtn} type="text" onClick={() => handleDownload()}>
                  <img
                    className={styles.imgDownload}
                    alt="logo"
                    width="11.67px"
                    height="14.17px"
                    src={download}
                  />
                  DOWNLOAD
                </button>
              </div>
              {/* {id} */}
              <div>
                <iframe src={pdfCv} className={styles.iFramePdf}></iframe>
              </div>
              {/* <div className={`${viewPdf ? styles.objectPdf : ""}`}>
                {viewPdf ? (
                  <button>
                    <a href={pdfCv} target="_blank">
                      <object data={pdfCv} type="application/pdf" width="900px" height="470%">
                        <p>
                          Alternative text - include a link{" "}
                          <a href={pdfCv} target="_blank">
                            to the PDF!
                          </a>
                        </p>
                      </object>
                    </a>
                  </button>
                ) : (
                  <SinglePagePDFViewer pdf={pdfCv} />
                )}
              </div> */}
            </div>
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default Download;
