import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import styles from "./Dropdown.module.scss";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .css-10bey84-MuiSvgIcon-root-MuiNativeSelect-icon": {
    right: "12px",
  },
  "& .MuiInputBase-input": {
    borderRadius: 5,
    letterSpacing: "0.15px",
    position: "relative",
    fontWeight: "400",
    backgroundColor: "#EBF1FE",
    border: "1px solid #C2C6D3",
    color: "rgba(58, 53, 65, 0.68)",
    fontSize: 16,
    height: 40,
    padding: "0 25px 0 17px",
    fontFamily: ["Inter"].join(","),
    "&:focus": {},
  },
}));

export const Dropdown = ({ width }) => {
  const items = ["Position", "sdsdsd", "sdsrre"];
  const [currentItem, setPersonField] = useState(``);

  const handleChange = (e) => {
    setPersonField(e.target.value);
  };

  useEffect(() => {
    if (!currentItem) return;
    // setPersonField(currentItem);
  });

  return (
    <div className={styles.dropdown}>
      <FormControl sx={{ m: 1, width }} variant="standard">
        <NativeSelect
          defaultValue="none"
          value={currentItem}
          onChange={handleChange}
          input={<BootstrapInput />}>
          {items.map((item) => (
            <option value={item} key={item}>
              {item}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    </div>
  );
};

Dropdown.propTypes = {
  width: PropTypes.string,
};
