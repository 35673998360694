import React, { useEffect, useState } from "react";
import styles from "./PreviewStep.module.scss";
import edit from "../../assets/svg/edit.svg";
import delIcon from "../../assets/svg/delete.svg";

const PreviewStep = ({ updateData, data, saveCv, edit, editCV }) => {
  const [achievements, setAchievements] = useState(data);
  const [isEdit, setIsEdit] = useState(false);
  const [pageNum, setPageNum] = useState({ name: 8 });
  const [formValid, setFormValid] = useState(true);

  useEffect(() => {
    if (achievements.length > 2) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [achievements]);

  const onSubmit = () => {
    if (edit) {
      editCV();
    } else {
      saveCv();
    }

    //updateData(pageNum.name);
    //history.push("/download");
  };

  const onToggleBack = () => {
    updateData(pageNum.name - 2);
  };

  const handleEdit = () => () => {
    setIsEdit((prevState) => !prevState);
  };

  const handleDelete = () => () => {
    setAchievements("");
  };
  return (
    <div>
      <div>
        <div>
          <div>
            <div className="formContainer">
              <h2 className="title">Summary</h2>
                <textarea
                  className="form_input"
                  rows="10"
                  style={{border:!isEdit && "none",resize:!isEdit && "none"}}
                  readOnly={!isEdit}
                  value={achievements}
                  onChange={(e) => setAchievements(e.target.value)}></textarea>
            
              <div className={styles.buttonsContainer}>
                <button className={styles.button} onClick={handleEdit()}>
                  <div className={styles.editContainer}>
                    <img src={edit} alt="" />
                    <div>EDIT</div>
                  </div>
                </button>
                <button className={styles.button} onClick={handleDelete()}>
                  <div className={styles.deleteContainer}>
                    <img src={delIcon} alt="" />
                    <div>DELETE</div>
                  </div>
                </button>
              </div>
            </div>
            <div className="buttonsContainer">
              <button className="buttonCancel" onClick={onToggleBack}>
                Back
              </button>
              <button className="buttonSave" disabled={formValid} onClick={onSubmit}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewStep;
