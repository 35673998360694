import React, { useState } from "react";
import styles from "./Tab.module.scss";
import {useHistory} from "react-router-dom";

const Tabs = ({active}) => {
    const history = useHistory();
    const [activeIndex, setActiveIndex] = useState(active);
    const ClickMyCvs = (index) => {
        setActiveIndex(index)
        history.push("/mycvs");
    };
    const ClickAllCvs = (index) => {
        setActiveIndex(index)
        history.push("/allcvs");
    };
    const checkActive = (index, className) => activeIndex === index ? className : "";
    return (
      <>
        <div className={styles.tabs}>
          <button
            className={`${styles.tab} ${checkActive(1, `${styles.active}`)}`}
            onClick={() => ClickMyCvs(1)}
          >
            MY CVS
          </button>
          <button
            className={`${styles.tab} ${checkActive(2, `${styles.active}`)}`}
            onClick={() => ClickAllCvs(2)}
          >
            ALL CVS
          </button>

        </div>
        <div>
          <div className={`${styles.panel} ${checkActive(1, `${styles.active}`)}`}>

          </div>
          <div className={`${styles.panel} ${checkActive(2, `${styles.active}`)}`}>

          </div>

        </div>
      </>
    );
  };


export default Tabs;
