import React, { useState, useEffect,useRef } from "react";
import { useHistory } from "react-router-dom";
import plus from "../assets/pictures/plus.png";
import logout from "../assets/svg/logout.svg";
import { onLogout } from "../AuthConfig";
import { getLoginToken, getLoginURL } from "../AuthConfig";
import { User } from "../config/api";

const NavbarLogout = () => {
  const history = useHistory();
  const user = User();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };
  
  const onTogleCv = () => {
    history.push("/mycvs");
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen && setIsOpen();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ isOpen ]);
// function wich take string and return first later 
const takeNameUser =(str)=>{
  const firstLetters = str
  .split(' ')
  .map(word => word[0])
  .join('');

  return firstLetters;
}
  const onTogleSteps = () => {
    history.push({
      pathname: "/steps",
      state: { addNewCV: true },
    });
  };

  const handleLogout = () => {
    onLogout();
  };

  return (
    <div className="Navbar">
      <button
        onClick={onTogleSteps}
        style={{ width: "160px", marginRight: "30px" }}
        className="navbar-btn"
        type="text">
        <img className="img-plus" alt="logo" width="16.67px" height="16.67px" src={plus} />
        add new cv
      </button>
      <div style={{ display: "inline-block" }} onClick={onTogleCv}>
        {/* <img className="img-cv" alt="logo" width="15.83px" height="18.33px" src={cv} /> */}
        <span style={{ marginRight: "20px", cursor: "pointer" }}>MY CVS</span>
      </div>

      <div  style={{ marginLeft: "15px", display: "inline-block" }}>
        <button className="navbar-user" type="text" onClick={handleOpen}>
          {takeNameUser(user.name)}
        </button>
      </div>
      {!isOpen ? (
        <span></span>
      ) : (
        <div  ref={ref} className="log_out_container">
          <div className="log_out_subcontainer">
            <div className="navbar-user">{takeNameUser(user.name)}</div>
            <div>
              <div style={{ fontSize: "14px", fontWeight: "600" }}> {user.name}</div>
              <div style={{ color: "rgba(58, 53, 65, 0.38)", fontSize: "13px", marginTop: "3px" }}>
                {user.email}
              </div>
            </div>
          </div>
          <div className="hr"></div>
          <div className="log_out_subcontainer" style={{ cursor: "pointer" }}>
            <div>
              <img src={logout} alt="" />
            </div>
            <div onClick={handleLogout}>Logout</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavbarLogout;
