exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TableTemplate_allCvs__3Y33F {\n  min-height: 88vh;\n  background: #EBF1FE; }\n  .TableTemplate_allCvs__3Y33F .TableTemplate_tabsContainer__2UJWd {\n    padding: 2% 0 0 10.5%; }\n  .TableTemplate_allCvs__3Y33F .TableTemplate_mains__TmCX7 {\n    display: flex;\n    justify-content: space-between;\n    margin: 0 1.5%; }\n    .TableTemplate_allCvs__3Y33F .TableTemplate_mains__TmCX7 .TableTemplate_filtersContainer__1lVCR {\n      margin: 9px 0 0 145px; }\n      .TableTemplate_allCvs__3Y33F .TableTemplate_mains__TmCX7 .TableTemplate_filtersContainer__1lVCR .TableTemplate_dropdowns__3_W1x {\n        display: inline-block;\n        position: relative;\n        top: -17px; }\n    .TableTemplate_allCvs__3Y33F .TableTemplate_mains__TmCX7 .TableTemplate_burgersContainer__1O1Tn {\n      margin-right: 106px; }\n      .TableTemplate_allCvs__3Y33F .TableTemplate_mains__TmCX7 .TableTemplate_burgersContainer__1O1Tn .TableTemplate_imgBurg__1lpIP {\n        position: relative;\n        right: 56px;\n        top: 14px; }\n      .TableTemplate_allCvs__3Y33F .TableTemplate_mains__TmCX7 .TableTemplate_burgersContainer__1O1Tn .TableTemplate_imgBurgvertical__Y-_Ho {\n        position: relative;\n        right: 33px;\n        top: 16px; }\n", ""]);

// exports
exports.locals = {
	"allCvs": "TableTemplate_allCvs__3Y33F",
	"tabsContainer": "TableTemplate_tabsContainer__2UJWd",
	"mains": "TableTemplate_mains__TmCX7",
	"filtersContainer": "TableTemplate_filtersContainer__1lVCR",
	"dropdowns": "TableTemplate_dropdowns__3_W1x",
	"burgersContainer": "TableTemplate_burgersContainer__1O1Tn",
	"imgBurg": "TableTemplate_imgBurg__1lpIP",
	"imgBurgvertical": "TableTemplate_imgBurgvertical__Y-_Ho"
};