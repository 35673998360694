exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SearchInput_searchInput__M4d4K {\n  position: relative;\n  display: inline-flex;\n  align-items: center;\n  margin-right: 8px; }\n  .SearchInput_searchInput__M4d4K .SearchInput_searchInput__searchButton__1N6fF {\n    position: absolute;\n    right: 30px;\n    width: 17.49px;\n    height: 17.49px;\n    border: none; }\n    .SearchInput_searchInput__M4d4K .SearchInput_searchInput__searchButton__1N6fF .SearchInput_searchInput__icon__2-F4y {\n      width: 16px; }\n  .SearchInput_searchInput__M4d4K .SearchInput_searchInput__input__2kO8c {\n    width: 226px;\n    height: 42px;\n    border-radius: 5px;\n    background: #EBF1FE;\n    padding: 0 16px;\n    border: 1px solid #C2C6D3; }\n\n.SearchInput_search-input__input__1A70z::placeholder {\n  font-size: 14px; }\n", ""]);

// exports
exports.locals = {
	"searchInput": "SearchInput_searchInput__M4d4K",
	"searchInput__searchButton": "SearchInput_searchInput__searchButton__1N6fF",
	"searchInput__icon": "SearchInput_searchInput__icon__2-F4y",
	"searchInput__input": "SearchInput_searchInput__input__2kO8c",
	"search-input__input": "SearchInput_search-input__input__1A70z"
};