import * as React from "react";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";

function DownloadIcon(props) {
  return (
    <SvgIcon style={{ color: "#8A8D93" }} {...props}>
      <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
    </SvgIcon>
  );
}

export const SvgIconsColor = () => {
  return (
    <Box
    //   sx={{
    //     '& > :not(style)': {
    //       m: 2,
    //     },
    //   }}
    >
      <DownloadIcon />
    </Box>
  );
};
