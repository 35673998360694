import endpoints from "../constants/endpoints";
import { instance } from "./index";
import axios from "axios";

const usersAPI = {
  getAll() {
    return instance.get(endpoints.USERSALL);
  },
};
export default usersAPI;
