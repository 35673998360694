exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Home_container__1AYu- .Home_resume__2v_5N {\n  font-size: \"24px\";\n  font-weight: \"500\";\n  line-height: \"32.02px\";\n  color: \"#333333\";\n  letter-spacing: \"0.15px\";\n  margin-bottom: \"13px\"; }\n\n.Home_container__1AYu- .Home_text__2WW6l {\n  width: \"467px\";\n  font-weight: \"400\";\n  font-size: \"16px\";\n  line-height: \"24px\";\n  color: \"#333333\";\n  letter-spacing: \"0.15px\";\n  margin-bottom: \"24px\"; }\n\n.Home_container__1AYu- .Home_button__3j00Z {\n  width: \"227px\";\n  background: \"#9155FD\";\n  color: \"#fff\";\n  margin-right: \"30px\"; }\n\n.Home_container__1AYu- .Home_image-plus__3rifC {\n  width: 16.67px;\n  height: 16.67px; }\n\n.Home_tabs__1tEbR {\n  padding-top: \"2%\";\n  padding-left: \"19%\"; }\n", ""]);

// exports
exports.locals = {
	"container": "Home_container__1AYu-",
	"resume": "Home_resume__2v_5N",
	"text": "Home_text__2WW6l",
	"button": "Home_button__3j00Z",
	"image-plus": "Home_image-plus__3rifC",
	"tabs": "Home_tabs__1tEbR"
};