import React from "react";
import { Box, Step, StepLabel, Stepper, StepButton } from "@mui/material";

const steps = [
  {
    label: "Contacts",
  },
  {
    label: "Education",
  },
  {
    label: "Certification",
  },
  {
    label: "Experience",
  },
  {
    label: "Skills",
  },
  {
    label: "Summary",
  },
  {
    label: "Preview",
  },
];

const LeftStepper = ({ step, updateData }) => {
  const [activeStep, setActiveStep] = React.useState(step);
  const handleStep = (stp) => () => {
    updateData(stp);
    setActiveStep(stp);
  };
  return (
    <div>
      <Box>
        <Stepper activeStep={step} nonLinear orientation="vertical">
          {steps.map((st, index) => (
            <Step key={st.label}>
              <StepButton onClick={handleStep(index + 1)}>{st.label}</StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  );
};

export default LeftStepper;
