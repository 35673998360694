exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Dropdown_dropdown__3pvh- {\n  display: inline-block;\n  justify-self: end; }\n", ""]);

// exports
exports.locals = {
	"dropdown": "Dropdown_dropdown__3pvh-"
};