
import { additional_API } from "../../Api/apis";
import { ADDITION_LOADING, GET_ADDITION_DONE, ADDITION_ERROR } from "../types/additionalTypes";

export const additionAction = {
    request: (loading) => ({
        type: ADDITION_LOADING,
        loading
    }),

    getReceive: (payload) => ({
        type: GET_ADDITION_DONE,
        payload
    }),
    failed: (e) => ({
        type: ADDITION_ERROR,
        error: e
    })
};


export const getAllAdditions = () => async (dispatch) => {
    const { request, getReceive, failed } = additionAction;
    dispatch(request(true));
    try {
        const res = await additional_API.getAllAddition()
        console.log("RES", res);
        dispatch(getReceive(res));
        dispatch(request(false));
    } catch (e) {
        console.log(e);
        dispatch(failed(e));
        dispatch(request(false));
    } finally {
        dispatch(request(false));
    }
};

