import React, { useEffect, useState, useRef } from "react";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { AuthHeader } from "../../constants/authHeader";
import styles from "./ContactsStep.module.scss";
import useStyles from "../../override";
const { REACT_APP_API_URL } = process.env;

const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

const years = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
];
const lengLevels = ["Elementary", "Pre-Intermediate", "Intermediate", "Upper-Intermediate", "Advanced", "Proficiency"]
const ContactsStep = ({
  updateData,
  handleContacts,
  handlePreviousContacts,
  previousContacts,
  countryData,
  countryID,
  jobData,
  jobDataId,
  languageData,
}) => {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState(null);
  const [job, setJob] = useState("");
  const [experience, setExperience] = useState("");
  const [experienceMonth, setExperienceMonth] = useState("");
  const [pageNum, setPageNum] = useState({ name: 2 });
  const [formValid, setFormValid] = useState(false);
  const [langFields, setLangFields] = useState([
    {
      id: "",
      code: "",
      level: "",
      name: "",
    },
  ])

  useEffect(() => {
    if (previousContacts.name) {
      setName(previousContacts.name);
    }
    if (previousContacts.lastName) {
      setLastName(previousContacts.lastName);
    }
    if (previousContacts.country) {
      setCountry(previousContacts.country);
    }
    if (previousContacts.job_title) {
      setJob(previousContacts.job_title);
    }
    if (previousContacts.experience) {
      setExperience(previousContacts.experience);
    }
    if (previousContacts.experienceMonth) {
      setExperienceMonth(previousContacts.experienceMonth);
    }
    if (previousContacts.language) {
      setLangFields(previousContacts.language);
    }
    // if (previousContacts) {
    //   setFormValue(previousContacts)
    // }
  }, [previousContacts]);

  const onSubmit = () => {
    handleContacts({
      name: name + " " + lastName,
      country: country,
      job_title: job,
      years_of_experience: experience,
      months_of_experience: experienceMonth,
      languages: langFields,
    });


    handlePreviousContacts({
      name: name,
      lastName: lastName,
      country: country,
      job_title: job,
      experience: experience,
      language: langFields,
      experienceMonth: experienceMonth,
    });

    updateData(pageNum.name);
  };
  const onCancel = () => {
    setName("");
    setLastName("");
    setCountry(null);
    setJob("");
    setExperience("");
    setExperienceMonth("");
    setLangFields([]);
    setFormValid(true);
  };


  const addFields = (e) => {
    e.preventDefault()
    let object = {
      name: "",
      code: "",
      level: "",
      id: ""
    };
    setLangFields([...langFields, object]);
  };

  const handleFormChange = (event, index, name) => {
    let data = [...langFields];
    const {
      target: { value },
    } = event;
    console.log(value);
    if (name === "language") {
      const langValue = languageData.find(i => i.name === value)
      data[index] = { ...data[index], ...langValue };
    }
    else {
      data[index] = { ...data[index], level: value };
    }
    setLangFields(data);

  };
  const removeFields = (event, index) => {
    event.preventDefault()
    setLangFields(langFields.filter((item, ind) => ind !== index));
  };
  const classes = useStyles();

  return (
    <div>
      <div>
        <div>
          <div className={`formContainer ${styles.formContainer}`}>
            <h2 className="title">Contact information</h2>
            <form>
              <div className="label">For example Sohn s. *</div>
              <div className={styles.wrapInputName}>
                <input
                  type="text"
                  className="form_input"
                  className={styles.firstName}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Full Name*"
                />
                <input
                  type="text"
                  className={styles.lastName}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last Name*"
                />
              </div>
              <Autocomplete
                className={classes.selectPicker}
                value={country}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => setCountry(value)}
                label="Country"
                disablePortal
                id="combo-box-demo"
                options={countryData}
                renderInput={(params) => <TextField {...params} label="Country *" />}
              />
              <Autocomplete
                className={classes.selectPicker}
                value={job}
                onChange={(e, value) => setJob(value)}
                getOptionLabel={(option) => option}
                label="Job Title"
                disablePortal
                freeSolo
                onInputChange={(e, value) => setJob(value)}
                id="combo-box-demo"
                options={jobData}
                renderInput={(params) => <TextField {...params} label="Job Title *" />}
              />

              <div className="dateLabel">Years of experience </div>

              <Autocomplete
                placeholder="Years of experience *"
                className={classes.selectPicker}
                value={experience}
                getOptionLabel={(option) => option}
                onChange={(e, value) => setExperience(value)}
                id="combo-box-demo"
                options={years}
                renderInput={(params) => <TextField {...params} />}
              />

              <div className="dateLabel">Months of experience </div>

              <Autocomplete
                className={classes.selectPicker}
                value={experienceMonth}
                getOptionLabel={(option) => option}
                onChange={(e, value) => setExperienceMonth(value)}
                id="combo-box-demo"
                options={months}
                renderInput={(params) => <TextField {...params} />}
              />

              <div >
                {langFields.map((item, index) => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%"
                        }}
                        key={index}
                      >
                        <FormControl sx={{ mt: 1, width: "50%" }}>
                          <InputLabel id="label">Language</InputLabel>
                          <Select
                          labelId="label"
                          id="select"
                          value={item.name}
                          defaultValue={""}
                          onChange={(e) => handleFormChange(e,index,"language")}
                          key={index}
                        >
                          {languageData.map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.name}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        </FormControl>
                        <FormControl sx={{ mt: 1, ml: 1, width: "50%" }}>
                          <InputLabel id="demo-simple-select-helper-label">Level</InputLabel>
                          <Select
                            value={item.level}
                            onChange={(e) => handleFormChange(e, index)}
                            name="level"
                          >
                            {lengLevels.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>

                        </FormControl>
                      </div>
                      <div className="cancelContainerExperience">
                        {langFields.length > 1 && (
                          <button
                            className="buttonCancel"
                            onClick={(event) => removeFields(event, index)}
                            style={{ marginTop: "10px" }}
                          >
                            Remove
                          </button>)}
                      </div>
                    </>
                  )
                })}
              </div>
              <div className="buttonsContainerExperienc" style={{ marginTop: "15px" }}>
                <button className="buttonSave" onClick={(e) => addFields(e)}>
                  Add New Language
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="buttonsContainer">
          <button className="buttonCancel" onClick={onCancel}>
            CANCEL
          </button>
          <button
            className={formValid ? "buttonSaveDisabled" : "buttonSave"}
            onClick={onSubmit}
            disabled={formValid}>
            SAVE & NEXT
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactsStep;
