exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SummaryStep_notesBlock__zXRdO {\n  width: 224px;\n  height: 267px;\n  background: #FAFAFA;\n  padding: 4px 8px;\n  border-radius: 4px;\n  margin: 82px 0 0 22px;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n  letter-spacing: 0.4px;\n  color: rgba(58, 53, 65, 0.68); }\n\nli span {\n  position: relative;\n  left: -10px; }\n\n.SummaryStep_wrap__GrrGb {\n  display: flex; }\n", ""]);

// exports
exports.locals = {
	"notesBlock": "SummaryStep_notesBlock__zXRdO",
	"wrap": "SummaryStep_wrap__GrrGb"
};