import React, { useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import styles from "./SummaryStep.module.scss";

const data =
  "Talented Administrative Assistant expertly uses computer programs, word processors and data\n" +
  "analysis tools. Increases team productivity by keeping files organized and handling multiple\n" +
  "assignments. Operates well with minimal oversight and meets tight deadlines.";

const SummaryStep = ({ updateData, achievementsHandler, data }) => {
  const [achievements, setAchievements] = useState(data ? data : "");
  const [pageNum] = useState({ name: 7 });
  const [formValid, setFormValid] = useState(false);

  // useEffect(() => {
  //   if (achievements.length > 2) {
  //     setFormValid(false);
  //   } else {
  //     setFormValid(true);
  //   }
  // }, [achievements]);

  const onSubmit = () => {
    updateData(pageNum.name);
    achievementsHandler(achievements);
  };

  const onToggleBack = () => {
    updateData(pageNum.name - 2);
  };

  return (
    <div>
      <div>
        <div>
          <div className={styles.wrap}>
            <span>
            <div className="formContainer">
              <h2 className="title">Summary*</h2>
              <form>
                <div>
                  <ToggleButtonGroup>
                    <ToggleButton value="bold" aria-label="bold">
                      <FormatBoldIcon />
                    </ToggleButton>
                    <ToggleButton value="italic" aria-label="italic">
                      <FormatItalicIcon />
                    </ToggleButton>
                    <ToggleButton value="underlined" aria-label="underlined">
                      <FormatUnderlinedIcon />
                    </ToggleButton>
                    <ToggleButton value="left" aria-label="left aligned">
                      <FormatAlignLeftIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <textarea
                  className="form_input"
                  rows="10"
                  placeholder={data}
                  value={achievements}
                  onChange={(e) => setAchievements(e.target.value)}></textarea>
              </form>
            </div>
            <div className="buttonsContainer">
              <button className="buttonCancel" onClick={onToggleBack}>
                Back
              </button>
              <button
                className={formValid ? "buttonSaveDisabled" : "buttonSave"}
                onClick={onSubmit}
                disabled={formValid}>
                SAVE & NEXT
              </button>
            </div>
            </span>
            <span>
            <div className={styles.notesBlock}>
              E.g.: (Please specify only those achievements which a) show proficiency; b) are
              extraordinary; c) save client’s money)
              <br />
              • Developed a mobile application that connects doctors with people whose smart watch
              recorded suspicious symptoms
              <br />
              • Won Ukrainian startup hackathon
              <br />• Redesigned 80%-complete mobile application and reduced the cost of maintenance
              to 60%.
            </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryStep;
