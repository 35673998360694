import defaultReducer from "../../redux/defaultReducer";
import thunkWrapper from "../../config/api/thunkWrapper";
import usersAPI from "../../config/api/usersApi";

export const getAllUsers = thunkWrapper("usersAPI", usersAPI.getAll);

export const usersSlice = defaultReducer(getAllUsers, true);

export const { clearState } = usersSlice.actions;

export const usersSelector = (state) => {
  return state.users.getAll.data;
};
