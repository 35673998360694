import axios from "axios";
import endpoints from "../constants/endpoints";
import { ROUTES } from "../../constants/routes";

const { REACT_APP_API_URL } = process.env;

export const baseURL = `${REACT_APP_API_URL}/`;

export const instance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const setTokens = (token, refreshToken) => {
  localStorage.setItem("token", token);
  localStorage.setItem("refreshToken", refreshToken);
};

export const setQueryParameters = (code, session_state, state, client_info) => {
  localStorage.setItem("code", code);
  localStorage.setItem("session_state", session_state);
  localStorage.setItem("state", state);
  localStorage.setItem("client_info", client_info);
};

export const code = () => localStorage.getItem("code");
export const session_state = () => localStorage.getItem("session_state");
export const state = () => localStorage.getItem("state");
export const client_info = () => localStorage.getItem("client_info");

export const setUser = (user) => {
  localStorage.setItem("user", user);
};
export const User = () => JSON.parse(localStorage.getItem("user"));

export const setLoginURL = (loginURL) => {
  localStorage.setItem("loginURL", JSON.stringify(loginURL));
};
export const LoginURL = () => localStorage.getItem("loginURL");

export const setAccessToken = (token) => {
  localStorage.setItem("token", token);
};

export const clearToken = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
};

export const getRefreshToken = () => localStorage.getItem("refreshToken");

export const getToken = () => localStorage.getItem("token");

export const tokenInstanceUser = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getToken()}`,
  },
});

tokenInstanceUser.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("error: ", error);
    window.location.href = ROUTES.login;
    return Promise.reject(error);

    // const userRequest = error.config;

    // if (
    //   error.response?.status === 401 &&
    //   userRequest.url === `${baseURL}${endpoints.REFRESH_TOKEN_USER}`
    // ) {
    //   window.location.href = ROUTES.login;
    //   return Promise.reject(error);
    // }

    // if (error.response?.status === 401 && !userRequest._retry) {
    //   userRequest._retry = true;
    //   const refreshToken = getRefreshToken();

    //   return axios
    //     .post(`${baseURL}${endpoints.REFRESH_TOKEN_USER}`, {
    //       refreshToken,
    //     })
    //     .then((res) => {
    //       let request = null;
    //       if (res.status === 200) {
    //         setAccessToken(res.data);
    //         request = tokenInstanceUser(userRequest);
    //       }
    //       return request;
    //     })
    //     .catch((err) => {
    //       Promise.reject(err);
    //     });
    // }
  }
);
